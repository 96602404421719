import { React, useState, useRef, useEffect } from "react";
import "./ChatbotWeb.css";
import { BsRobot } from "react-icons/bs";
import axios from "axios";
import { AiOutlineMinus, AiOutlineSend } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";

const ChatbotWeb = () => {
  const [arrowVisible, setArrowVisible] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [userMessages, setUserMessages] = useState([]);
  const [chatbotMessages, setChatbotMessages] = useState([]);
  const chatbotBodyRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    scrollToBottom();
  }, [chatbotMessages]);

  useEffect(() => {
    if (
      blocked &&
      chatbotMessages[chatbotMessages.length - 1] !==
        "You have reached your maximum allowed number of inquiries for today! You can chat with us again tomorrow."
    ) {
      setChatbotMessages([
        ...chatbotMessages,
        "You have reached your maximum allowed number of inquiries for today! You can chat with us again tomorrow.",
      ]);
    }
  }, [blocked, chatbotMessages]);

  const autoGrow = (event) => {
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };
  const checkScrollPosition = () => {
    if (!chatbotBodyRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = chatbotBodyRef.current;
    const tolerance = 5;
    const isAtBottom = scrollTop + clientHeight + tolerance >= scrollHeight;

    setArrowVisible(!isAtBottom);
  };

  const ArrowscrollToBottom = () => {
    if (!chatbotBodyRef.current) return;
    chatbotBodyRef.current.scrollTop = chatbotBodyRef.current.scrollHeight;
  };

  const toggleExpanded = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsExpanded(!isExpanded);
      setIsClosing(false);
    }, 400);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleData();
      e.target.style.height = "auto";
    }
  };

  const scrollToBottom = () => {
    if (chatbotBodyRef.current) {
      chatbotBodyRef.current.scrollTop = chatbotBodyRef.current.scrollHeight;
    }
  };

  const handleData = async () => {
    const wordCount = userInput.trim().split(/\s+/).length;
    if (wordCount > 50) {
      alert("please limit your query to 50 words");
    }
    scrollToBottom();
    setUserMessages([...userMessages, userInput]);
    setIsLoading(true);
    setUserInput("");

    // Display the language limitation message when non-English characters are detected
    if (/[^ -~\s]/.test(userInput)) {
      setChatbotMessages([
        ...chatbotMessages,
        "Sorry our chatbot service only supports English language at the moment!",
      ]);
      return;
    }
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    let data;

    try {
      const response = await axios.post(`${apiUrl}/chat`, {
        chat: {
          query: userInput + " ->",
        },
      });
      data = response.data;
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setBlocked(true);
        setIsLoading(false);
        console.log(error);
        return;
      } else {
        alert(error.message);
        console.log(error);
      }
    }
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const response = data.chat.response;

    let cleanedResponse = response
      .replace(/^\s*[,!]/gm, "")
      .replace(/[^ -~\s]/g, "") // Remove spaces, commas, and exclamation marks at the beginning of the string
      .split(/[\r\n]/)[0]
      .trim();

    const arrowIndex = cleanedResponse.indexOf("->");
    if (arrowIndex !== -1) {
      cleanedResponse = cleanedResponse.substring(0, arrowIndex).trim();
    }
    const exclamationIndex = cleanedResponse.indexOf("!");
    if (exclamationIndex !== -1) {
      cleanedResponse = cleanedResponse.substring(0, exclamationIndex).trim();
    }
    // Find the first occurrence of a question mark, a full stop followed by "com", or a full stop
    const firstMatch = cleanedResponse.match(/(\?|(\.com)|\.)/);
    const result = firstMatch
      ? cleanedResponse.split(firstMatch[0])[0] + firstMatch[0]
      : cleanedResponse;

    // Remove everything after "com" if it exists
    const comMatch = result.match(/com/);
    if (comMatch) {
      cleanedResponse = result.substring(
        0,
        comMatch.index + comMatch[0].length
      );
    } else {
      cleanedResponse = result;
    }

    setChatbotMessages([...chatbotMessages, result]);
    setIsLoading(false);
  };

  useEffect(() => {
    scrollToBottom();
  }, [userMessages, chatbotMessages]);

  useEffect(() => {
    if (chatbotBodyRef.current) {
      const currentChatbotBody = chatbotBodyRef.current;
      currentChatbotBody.addEventListener("scroll", checkScrollPosition);

      return () => {
        currentChatbotBody.removeEventListener("scroll", checkScrollPosition);
      };
    }
  }, [isExpanded]);

  return (
    <div
      className={`chatbot-web ${isExpanded ? "expanded" : ""}
      }`}
    >
      <div
        className="chatbot-toggle"
        onClick={toggleExpanded}
        style={isExpanded ? { display: "none" } : {}}
      >
        <BsRobot size={35} className="one-time-beat" color="white" />
      </div>
      {isExpanded && (
        <div className={`chatbot-box ${isClosing ? "closing" : ""}`}>
          <div className="chatbot-header">
            <h4 className="chatbot-header-text">
              <span
                className="chatbot-header-text-span"
                style={{ fontSize: "1.25rem" }}
              >
                <div
                  style={{
                    fontFamily: "Comfortaa",
                    padding: "0.1rem",
                    fontSize: "2rem",
                  }}
                >
                  {"<"}
                </div>
                <b>swlancer</b>
                <div style={{ fontFamily: "Comfortaa", fontSize: "2rem" }}>
                  {"/>"}
                </div>
              </span>{" "}
              Agent
            </h4>
            <button onClick={toggleExpanded} className="chatbot-close-button">
              <AiOutlineMinus size={30} />
            </button>
          </div>
          <div className="chatbot-body" ref={chatbotBodyRef}>
            <div
              className={`arrowDown ${!arrowVisible ? "hidden" : ""}`}
              onClick={ArrowscrollToBottom}
            >
              <IoIosArrowDown size={20} />
            </div>
            {
              <div className="message-row">
                <div className="chatbot-message-container">
                  <div className="robo-icon-container">
                    <BsRobot className="robo-icon" />
                  </div>
                  <div className="chatbot-message">
                    Welcome to SWLANCER chatbot powered by ChatGPT. How can I be
                    of assistance?
                  </div>
                </div>
              </div>
            }
            {userMessages.map((message, index) => (
              <div className="message-row" key={index}>
                <div className="user-message-container">
                  <div className="user-message">{message}</div>
                </div>
                {message && (
                  <div className="chatbot-message-container">
                    <div className="robo-icon-container">
                      <BsRobot className="robo-icon" />
                    </div>
                    <div className="chatbot-message">
                      {isLoading && index === chatbotMessages.length ? (
                        <div className="loader">
                          <div />
                          <div />
                          <div />
                        </div>
                      ) : (
                        chatbotMessages[index]
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <form onSubmit={handleData} className="chatbot-input">
            <textarea
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              className="chatbot-input-box"
              onKeyDown={handleKeyDown}
              onInput={autoGrow}
              placeholder="Type your message..."
              rows={1}
              resizing="false"
              disabled={blocked}
              maxLength={300}
            />
            <AiOutlineSend
              size={30}
              color="#778899"
              className={`chatbot-send-button ${
                blocked ? "disabled-icon" : ""
              }`}
              onClick={handleData}
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatbotWeb;
