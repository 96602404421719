import React, { useState } from "react";
import Slider from "react-slick";
import "./SlickSlider.css";
import { FaDocker, FaLinux, FaServer } from "react-icons/fa";
import { BsClipboardData } from "react-icons/bs";
import { IoPeopleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const SlickSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  let navigate = useNavigate();

  const NavigateTo = (path) => {
    console.log(path);
    navigate(`/${path}`);
  };

  const cardData = [
    {
      path: "Managed_services",
      imgSrc: <FaLinux size={50} />,
      imgAlt: "Linux icon",
      title: "Managed Services",
      desc: "SWLancer provides a range of managed services to ensure your systems and applications run smoothly. We offer Linux support services for all flavors of Linux operating systems.",
    },
    {
      path: "hosting",
      imgSrc: <FaServer size={50} />,
      imgAlt: "server icon",
      title: "Hosting",
      desc: "SWLancer provides a range of hosting services to ensure your website or application runs smoothly. We offer hosting services on popular hosting platforms such as Hostinger, GitHub, and Godaddy, as well as cloud hosting services",
    },
    {
      path: "containers",
      imgSrc: <FaDocker size={50} />,
      imgAlt: "Docker icon",
      title: "Containerized workloads",
      desc: "SWLancer offers containerization services using Docker and Kubernetes solution architect for modular growth. Our solutions deployment ensures that your application is deployed quickly and easily, with minimal downtime.",
    },
    {
      path: "consulting",
      imgSrc: <IoPeopleSharp size={50} />,
      imgAlt: "Consultancy icon",
      title: "Consultancy",
      desc: "SWLancer provides a range of consultancy services for IT infrastructure setup involving local network setup using VLANs and core switch routing solutions, VoIP integration ...",
    },
    {
      path: "Data_Modeling",
      imgSrc: <BsClipboardData size={50} />,
      imgAlt: "Data Modeling icon",
      title: "Data Modeling and Analytics",
      desc: "SWLancer data modeling and analysis services includes Microsoft PowerBI dashboard designs with customizations for in-depth trend analysis. We also specialize in building, training, and integrating ML models into business logic.",
    },
  ];
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          autoplaySpeed: 3000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="servicesSection">
      <div className="titleContainer">
        <div className="text-start">
          <h2 className="inner-section-title">OUR SERVICES</h2>
        </div>
      </div>
      <div className="sliderContainer">
        <Slider
          {...settings}
          beforeChange={(oldIndex, newIndex) => {
            if (newIndex !== activeSlide) {
              setActiveSlide(newIndex);
            }
          }}
          afterChange={(current) => setActiveSlide(current)}
        >
          {cardData.map((card, index) => (
            <div
              className={`service-card slick-slide ${
                activeSlide === index
                  ? window.innerWidth <= 767
                    ? "active-card-mobile"
                    : "active-card"
                  : ""
              }`}
              tabIndex="0"
              data-slick-index={index}
              aria-hidden="false"
              key={index}
              onClick={() => NavigateTo(card.path)}
            >
              <div>{card.imgSrc}</div>
              <div className="service-card-title">{card.title}</div>
              <div className="service-card-desc ">{card.desc}</div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default SlickSlider;
