import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/homepage";
import "./App.css";
import Solutions from "./Pages/Solutions";
import ReactGA from "react-ga";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import GoogleAnalytics from "./GoogleAnalytics";
import AboutUs from "./Pages/AboutUS";
import ScrollToTop from "./Tools/ScrollToTop";
import ContactUs from "./Pages/ContactUs";
import LanguagesPage from "./Components/LanguagesPage";
import ServicesPage from "./Components/ServicesPage";
import PageData from "./Data/BasicPageData";
import pages from "./Data/LanguagesPageData";
import Tools from "./Pages/Tools";
import Services from "./Pages/services";
import Careers from "./Pages/Careers";
import Portfolio from "./Pages/portfolio";
function App() {
  ReactGA.initialize("G-R099QQWWK7");
  const siteKey = process.env.REACT_APP_SITE_KEY;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const ExternalRedirect = ({ url }) => {
    window.location.href = url; // Redirect to external URL
    return null; // No need to render anything
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      <div className="App">
        <GoogleAnalytics />
        <GoogleReCaptcha onVerify={(token) => console.log(token)} />
        <Router>
          <ScrollToTop />
          <Routes>
            <Route index element={<Homepage />} />
            <Route path="solutions" element={<Solutions />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="contactus" element={<ContactUs />} />
            <Route path="tools" element={<Tools />} />
            <Route path="services" element={<Services />} />
            <Route path="careers" element={<Careers />} />
            <Route path="Portfolio" element={<Portfolio />} />
            <Route
              path="HealthFlow"
              element={<ExternalRedirect url="https://healthflowonline.com" />}
            />
            <Route path="*">404 Not Found</Route>

            {pages.map((page, index) => (
              <Route
                key={index}
                path={page.path}
                element={
                  <LanguagesPage
                    title={page.title}
                    vid={page.vid}
                    logo={page.logo}
                    key={page.key}
                    textarray={page.textarray}
                    links={page.links}
                    slogan={page.slogan}
                    backgroundImageTitle={page.backgroundImageTitle}
                    textType={page.textType}
                    TextTitle={page.TextTitle}
                  />
                }
              />
            ))}
            {PageData.map((page, index) => (
              <Route
                key={index}
                path={page.path}
                element={
                  <ServicesPage
                    header={page.header}
                    listItems={page.listItems}
                    text={page.text}
                    icon={page.icon}
                    textarray={page.textarray}
                    links={page.links}
                    key={page.key}
                    backgroundImageTitle={page.backgroundImageTitle}
                    type={page.type}
                    TextTitle={page.TextTitle}
                    textType={page.textType}
                  />
                }
              />
            ))}
          </Routes>
        </Router>
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;
