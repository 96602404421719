import React from "react";
import Footer from "../Components/Footer";
import "./Solutions.css";
import Navbar from "../Components/Navbar";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaDocker, FaLinux, FaServer } from "react-icons/fa";
import { BsClipboardData } from "react-icons/bs";
import { IoPeopleSharp } from "react-icons/io5";

const Services = () => {
  let navigate = useNavigate();
  const NavigateTo = (path) => {
    navigate(`/${path}`);
  };

  const cards = [
    {
      path: "containers",
      imageUrl: <FaDocker size={60} className="basic-page-icon" />,
      altText: "docker icon",
      cardText: "Containerized workloads",
    },
    {
      path: "Managed_services",
      imageUrl: <FaLinux size={60} className="basic-page-icon" />,
      altText: "linux icon",
      cardText: "Managed Services",
    },
    {
      path: "consulting",
      imageUrl: <IoPeopleSharp size={60} className="basic-page-icon" />,
      altText: "consulting icon",
      cardText: "Consultation",
    },
    {
      path: "hosting",
      imageUrl: <FaServer size={60} className="basic-page-icon" />,
      altText: "hosting icon",
      cardText: "Hosting",
    },
    {
      path: "Data_Modeling",
      imageUrl: <BsClipboardData size={60} className="basic-page-icon" />,
      altText: "data modeling icon",
      cardText: "Data Modeling & Analysis",
    },
  ];

  return (
    <div className="solutions-BG">
      <Navbar isgrey={true} />
      <Container style={{ padding: "7rem" }}>
        <Row>
          <Col>
            <h1 className="text-center pt-5">Services</h1>
          </Col>
        </Row>
        <Row className="justify-content-md-center my-4">
          {cards.map((card) => (
            <Col md="auto" key={card.path}>
              <Card
                className="mb-3 w-100 custom-card border-0"
                onClick={() => NavigateTo(card.path)}
              >
                <div
                  className="d-flex flex-column align-items-start gap-4"
                  style={{ padding: "1.5rem" }}
                >
                  <div>{card.imageUrl}</div>
                  <p className="cards-txt">{card.cardText}</p>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default Services;
