/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

const GoogleAnalytics = () => {
  const GA_MEASUREMENT_ID = "G-R099QQWWK7";
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", GA_MEASUREMENT_ID);
  }, []);

  return null;
};

export default GoogleAnalytics;
