import React, { useState, useRef, useEffect } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import SuggestionsPanel from "./SuggestionsPanel";
import "./SearchBar.css";
import { useNavigate } from "react-router-dom";

const languages = [
  "Node",
  "Javascript",
  "React",
  "Docker",
  "Kubernetes",
  "Flutter",
  "Github",
  "Python",
  "web_development",
  "chatbots",
  "mobile_apps",
  "web_services",
  "hosting",
  "managed_services",
  "consulting",
  "containers",
  "data_modeling",
  "contactUs",
  "Careers",
  "HealthFlow",
  "patient",
  "clinic",
];

const filterSuggestions = (query, array) =>
  array.filter((section) =>
    section.toLowerCase().startsWith(query.toLowerCase())
  );

const ExpandingSearchBar = ({ insideDrawer, withBorder, hoverColor }) => {
  const [focused, setFocused] = useState(false);
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);
  const [clickedoutside, setClickedOutside] = useState(false);

  const handleFocus = () => {
    if (!insideDrawer) {
      setFocused(true);
    }
  };

  const toggleSearchBar = () => {
    setIsSearchBarVisible((prevVisible) => !prevVisible);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleClickOutside = (e) => {
    if (
      !insideDrawer &&
      isSearchBarVisible &&
      !e.target.closest(".search-container") &&
      !e.target.closest(".burger-menu")
    ) {
      setIsSearchBarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      setClickedOutside(true);
    };
  });

  const inputRef = useRef();
  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setSuggestions([
      ...filterSuggestions(value, languages).map((s) => ({
        value: s,
        origin: "languages",
      })),
    ]);

    if (value === "") {
      setSuggestions([]);
    }
  };
  const handleClearInput = () => {
    setQuery("");
    setSuggestions([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab" && suggestions.length > 0) {
      event.preventDefault();
      setQuery(suggestions[0].value);
    }
  };

  let navigate = useNavigate();
  const handleSuggestionClick = (value) => {
    setFocused(false);
    setSuggestions([]);
    setQuery(value);

    if (value === "patient" || value === "clinic") {
      navigate(`/HealthFlow/`);
      return;
    }
    navigate(`/${value}`);
  };

  return (
    <div className={`search-container ${focused ? "focused" : ""}`}>
      <input
        className={`search-bar ${
          focused || isSearchBarVisible ? "expanded" : "collapsed"
        } ${!isSearchBarVisible && !focused ? "instant-close" : ""}  ${
          withBorder ? "search-bar-border" : ""
        }`}
        onFocus={handleFocus}
        ref={inputRef}
        onBlur={handleBlur}
        onChange={handleInputChange}
        value={query}
        placeholder=""
        type="text"
        aria-activedescendant={
          suggestions.length > 0 ? `suggestion-${suggestions[0].value}` : null
        }
        onKeyDown={handleKeyDown}
      />
      <div className="search-icon-container">
        {query.length === 0 || clickedoutside ? (
          <FaSearch
            className={`icon ${isSearchBarVisible ? "icon-grey" : ""} ${
              hoverColor ? "icon-blue" : ""
            }`}
            onClick={toggleSearchBar}
          />
        ) : (
          <FaTimes className="icon delete" onClick={handleClearInput} />
        )}
      </div>
      {suggestions.length > 0 && (
        <SuggestionsPanel
          suggestions={suggestions}
          onSuggestionClick={handleSuggestionClick}
          className={`suggestions-panel${
            focused || isSearchBarVisible ? "expanded" : "collapsed"
          }`}
        />
      )}
    </div>
  );
};

export default ExpandingSearchBar;
