import React, { useState, useEffect } from "react";
import sanityClient from "../sanityClient";
import createImageUrlBuilder from "@sanity/image-url";

const SanityImage = ({ imageTitle, className, type }) => {
  const [imageUrl, setImageUrl] = useState("");
  const [imageDescription, setImageDescription] = useState("");

  useEffect(() => {
    const getImageUrl = (imageAsset) => {
      const builder = createImageUrlBuilder({
        projectId: "fxusszjf",
        dataset: "production",
        apiVersion: "2021-03-25",
        useCdn: true,
      });

      return builder.image(imageAsset).url();
    };

    const cacheKey = `${type}-${imageTitle}`;
    const query = `*[_type == '${type}' && title == '${imageTitle}']`;

    const fetchAndUpdateImage = async () => {
      const imageData = await sanityClient.fetch(query);
      const targetImage = imageData[0]; // Get the first image from the result array
      const imageUrl = getImageUrl(targetImage.image);

      // Cache the image
      const imageDataToCache = {
        url: imageUrl,
        description: targetImage.description,
      };

      localStorage.setItem(cacheKey, JSON.stringify(imageDataToCache));

      setImageUrl(imageUrl);
      setImageDescription(targetImage.description);
    };

    const checkCacheAndUpdateImage = async () => {
      const cachedImageData = localStorage.getItem(cacheKey);

      if (cachedImageData) {
        const { url } = JSON.parse(cachedImageData);
        setImageUrl(url);
      } else {
        fetchAndUpdateImage();
      }
    };

    const subscription = sanityClient.listen(query).subscribe(() => {
      checkCacheAndUpdateImage();
    });

    checkCacheAndUpdateImage();

    return () => {
      subscription.unsubscribe();
    };
  }, [imageTitle, type]);

  return (
    <img
      className={className}
      src={imageUrl}
      alt={imageDescription}
      loading="lazy"
      style={{ visibility: imageUrl ? "visible" : "hidden" }}
    />
  );
};

export default SanityImage;
