import React, { useState } from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import { Container, Col, Form, Button, Row } from "react-bootstrap";
import { BsTelephoneFill } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import axios from "axios";
import "./ContactUs.css";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const [sent, setSent] = useState(false);
  const [errorFields, setErrorFields] = useState({
    name: false,
    email: false,
    phone: false,
    company: false,
    message: false,
  });

  const handleSubmit = async (e) => {
    if (sent) {
      return;
    }

    e.preventDefault();

    if (!name || !email || !phone || !company || !message) {
      setError("Please fill in all fields!");
      setMessageColor("red");
      setErrorFields({
        name: !name,
        email: !email,
        phone: !phone,
        company: !company,
        message: !message,
      });
      return;
    }

    const subject = "contact form submission";
    const data = {
      name,
      email,
      phone,
      company,
      message,
      subject,
    };

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    console.log("ApiUrl :  " + apiUrl);
    // axios.post(`${apiUrl}/send-email`, da
    try {
      await axios.post(`${apiUrl}/send-email`, data);
      setError("Email sent successfully");
      setSent(true);
      setMessageColor("green");
    } catch (error) {
      if (error.response && error.response.status === 429) {
        alert("Too many requests, please try again later");
        return;
      }
      setError(error.response.data.error + "Error sending email");

      setMessageColor("red");
    }
  };

  const handleInputChange = (e, field) => {
    if (errorFields[field]) {
      setErrorFields({ ...errorFields, [field]: false });
    }

    switch (field) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
      case "company":
        setCompany(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Navbar isgrey={true} />
      <Container style={{ paddingTop: "10rem", paddingBottom: "4rem" }}>
        <Row className="justify-content-md-center">
          <Col md={5}>
            <h3 className="header-ContactUs">Get In Touch</h3>
            <ul className="ul-contactUs">
              <li>
                <BsTelephoneFill size={17} className="contactUsIcons" />
                Phone: +961 76 407 089
              </li>
              <li>
                <MdEmail className="contactUsIcons" size={20} />
                info@swlancer.com
              </li>
              <li>
                <IoLocationSharp className="contactUsIcons" size={22} />
                Beirut, Lebanon
              </li>
            </ul>
          </Col>

          <Col md={5}>
            <Form onSubmit={handleSubmit} className="mb-3 contactUs-form">
              {error && (
                <p
                  style={{
                    color: messageColor,
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {error}
                </p>
              )}
              <Form.Label
                className="text-left"
                style={{ fontFamily: "Poppins" }}
              >
                Please enter your details to receive a callback from our team
              </Form.Label>
              <Row>
                <Col md={6}>
                  <Form.Group
                    controlId="name"
                    className={`mb-3 ${errorFields.name ? "error-field" : ""}`}
                  >
                    <Form.Control
                      type="text"
                      placeholder="name *"
                      value={name}
                      onChange={(e) => handleInputChange(e, "name")}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    controlId="email"
                    className={`mb-3 ${errorFields.email ? "error-field" : ""}`}
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email *"
                      value={email}
                      onChange={(e) => handleInputChange(e, "email")}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group
                    controlId="phone"
                    className={`mb-3 ${errorFields.phone ? "error-field" : ""}`}
                  >
                    <Form.Control
                      type="tel"
                      placeholder="phone number *"
                      value={phone}
                      onChange={(e) => handleInputChange(e, "phone")}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    controlId="company"
                    className={`mb-3 ${
                      errorFields.company ? "error-field" : ""
                    }`}
                  >
                    <Form.Control
                      type="text"
                      placeholder="company name *"
                      value={company}
                      onChange={(e) => handleInputChange(e, "company")}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group
                controlId="message"
                className={`mb-3 ${errorFields.message ? "error-field" : ""}`}
              >
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Message *"
                  value={message}
                  onChange={(e) => handleInputChange(e, "message")}
                />
              </Form.Group>
              <Row className="text-left">
                <Col md={5}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="mt-3  btn-contacUs-form border-0"
                    style={{ borderRadius: "0" }}
                  >
                    Submit Message
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default ContactUs;
