import { CgWebsite } from "react-icons/cg";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";
import { BsRobot } from "react-icons/bs";
import { FaDocker, FaLinux, FaServer } from "react-icons/fa";
import { IoIosApps } from "react-icons/io";
import { BsClipboardData } from "react-icons/bs";
import { IoPeopleSharp } from "react-icons/io5";
import { IoEyeSharp } from "react-icons/io5";

const Solutionslinks = [
  { path: "/Solutions_Overview", label: "Overview" },
  { path: "/web_development", label: "Web Development" },
  { path: "/web_apps", label: "Web Apps" },
  { path: "/web_services", label: "Web Services" },
  { path: "/mobile_apps", label: "Mobile Apps" },
  { path: "/chatbots", label: "Chatbots" },
];

const Serviceslinks = [
  { path: "/Services_Overview", label: "Overview" },
  { path: "/hosting", label: "Hosting" },
  { path: "/Managed_services", label: "Managed Services" },
  { path: "/consulting", label: "Consulting" },
  { path: "/Data_Modeling", label: "Data Modeling" },
  { path: "/containers", label: "Containers" },
];

const PageData = [
  {
    path: "/hosting",
    header: "Hosting",
    listItems: [
      "AWS",
      "Azure",
      "Google Cloud",
      "Hostinger",
      "Godaddy",
      "GitHub",
    ],
    text: "Hosting Solutions with SWLancer on ",
    icon: <FaServer color="white" size={50} />,
    textarray: ["AWS", "Azure", "Hostinger"],
    links: Serviceslinks,
    key: 1,
    backgroundImageTitle: "hosting",
    type: "services_images",
    TextTitle: "hosting text",
    textType: "Services_text",
  },
  {
    path: "/Managed_services",
    header: "Managed Services",
    listItems: [
      "Linux administration",
      "Full automation",
      "Database administration and tuning",
      "Backup and restoration tasks",
      "Security fortification",
    ],
    text: "Managed Services with SWLancer Using ",
    icon: <FaLinux color="white" size={50} />,
    textarray: ["Linux", "Ubuntu", "Redis"],
    links: Serviceslinks,
    key: 2,
    backgroundImageTitle: "managed services",
    type: "services_images",
    TextTitle: "managed services text",
    textType: "Services_text",
  },
  {
    path: "/containers",
    header: "Containerized Workloads",
    listItems: ["Docker", "Kubernetes"],
    text: "Containerization with SWLancer Using",
    icon: <FaDocker color="white" size={50} />,
    textarray: ["Docker", "Kubernetes"],
    links: Serviceslinks,
    key: 3,
    backgroundImageTitle: "containers",
    type: "services_images",
    TextTitle: "containers text",
    textType: "Services_text",
  },
  {
    path: "/Data_Modeling",
    header: "Data Modeling",
    listItems: [
      "Our data modeling and analysis services include the following:",
      "Microsoft Power BI dashboard design and customization",
      "Machine learning model development and training",
      "Integration of machine learning models into business logic",
      "Data visualization and analysis",
      "Data-driven insights for business decision-making",
    ],
    text: "Data Modeling with SWLancer Using ",
    icon: <BsClipboardData color="white" size={50} />,
    textarray: ["PowerBI", "Python"],
    links: Serviceslinks,
    key: 4,
    backgroundImageTitle: "data modeling",
    type: "services_images",
    TextTitle: "data modeling text",
    textType: "Services_text",
  },
  {
    path: "/consulting",
    header: "Consultation",
    listItems: [
      "Network Design",
      "Network Security",
      "VoIP Integration",
      "Hypervisors Deployment",
      "Microsoft Licensing",
    ],
    text: "Consultations with SWLancer in ",
    icon: <IoPeopleSharp color="white" size={50} />,
    textarray: ["Security", "Network", "Design"],
    links: Serviceslinks,
    key: 5,
    backgroundImageTitle: "consultation",
    type: "services_images",
    TextTitle: "consultation text",
    textType: "Services_text",
  },
  {
    path: "/chatbots",
    header: "Chatbots",
    listItems: [
      "Tensorflow NLP",
      "ChatGPT GPT-3 and GPT-3.5",
      "Model training",
    ],
    text: "Building Chatbots with SWLancer Using",
    icon: <BsRobot color="white" size={50} />,
    textarray: ["Python", "TensorFlow", "PyTorch"],
    links: Solutionslinks,
    key: 6,
    backgroundImageTitle: "chatbots",
    type: "solutions_images",
    TextTitle: "chatbots paragraph",
    textType: "Solutions_text",
  },
  {
    path: "/mobile_apps",
    header: "Mobile Apps",
    listItems: [
      "Flutter",
      "React Native",
      "Highly customized mobile apps",
      "Unique business requirements",
    ],
    text: "Build Mobile Apps with SWLancer in ",
    icon: <CiMobile3 color="white" size={50} />,
    textarray: ["Flutter", "React Native"],
    links: Solutionslinks,
    key: 7,
    backgroundImageTitle: "mobile apps",
    type: "solutions_images",
    TextTitle: "mobile apps paragraph",
    textType: "Solutions_text",
  },
  {
    path: "/web_services",
    header: "Web Services",
    listItems: [
      "RestFULL web API ",
      "Soap web API ",
      "Load Balancers",
      "For modern platforms ",
      "For legacy business platforms",
    ],
    text: "Web Services With SWLancer :  ",
    icon: <MdOutlineMiscellaneousServices color="white" size={50} />,
    textarray: ["Load Balancers", "RestFULL API", "Soap API"],
    links: Solutionslinks,
    key: 8,
    backgroundImageTitle: "web services",
    type: "solutions_images",
    TextTitle: "web services paragraph",
    textType: "Solutions_text",
  },
  {
    path: "/web_development",
    header: "Web Development",
    listItems: [
      "Online stores: Shopify and Squarespace",
      "Web development solutions based on popular open-source CMS platforms such as WordPress, Drupal, and Wix",
      "Websites from ground-up using React, Angular, HTML5, and CSS3",
      "Built-in CMS from Sanity, React Admin, and other frameworks",
    ],
    text: "Website Development Using ",
    icon: <CgWebsite color="white" size={50} />,
    textarray: ["React", "Shopify", "Squarespace", "WordPress", "Drupal"],
    links: Solutionslinks,
    key: 9,
    backgroundImageTitle: "website development",
    type: "solutions_images",
    TextTitle: "website development paragraph",
    textType: "Solutions_text",
  },
  {
    path: "/web_apps",
    header: "Web Apps",
    listItems: [
      "Front End web development ",
      "Back End web development ",
      "Database design and development ",
      "Popular Stacks deployment",
    ],
    text: "Building Web Apps Using ",
    icon: <IoIosApps color="white" size={50} />,
    textarray: ["React", "MongoDB", "Node.js", "MySql", "Express.js"],
    links: Solutionslinks,
    key: 10,
    backgroundImageTitle: "web apps",
    type: "solutions_images",
    TextTitle: "web apps paragraph",
    textType: "Solutions_text",
  },
  {
    path: "/Solutions_Overview",
    header: "Overview",
    listItems: [],
    text: "Software Solutions with SWLancer : ",
    icon: <IoEyeSharp color="white" size={50} />,
    textarray: [
      "Mobile Apps",
      "Web Apps",
      "Web Services",
      "Chatbots",
      "Websites",
    ],
    links: Solutionslinks,
    key: 11,
    backgroundImageTitle: "solutions overview",
    type: "solutions_images",
    TextTitle: "solutions overview",
    textType: "Solutions_text",
  },
  {
    path: "/Services_Overview",
    header: "Overview",
    listItems: [],
    text: "SWLancer Services Include : ",
    icon: <IoEyeSharp color="white" size={50} />,
    textarray: ["Hosting", "Containers", "Consultation", "Data Modeling"],
    links: Serviceslinks,
    key: 12,
    backgroundImageTitle: "services overview",
    type: "services_images",
    TextTitle: "services overview",
    textType: "Services_text",
  },
];

export default PageData;
