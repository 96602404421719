import YoutubeEmbed from "../Tools/YoutubeEmbed";
import { IoLogoJavascript } from "react-icons/io";
import { SiDocker, SiFlutter, SiJenkins, SiKubernetes } from "react-icons/si";
import { FaGithub, FaNode, FaPython, FaReact } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
const links = [
  { path: "/Tools_Overview", label: "Overview" },
  { path: "/Docker", label: "Docker" },
  { path: "/Flutter", label: "Flutter" },
  { path: "/Github", label: "GitHub" },
  { path: "/Jenkins", label: "Jenkins" },
  { path: "/Javascript", label: "JavaScript" },
  { path: "/Kubernetes", label: "Kubernetes" },
  { path: "/Node", label: "Node.js" },
  { path: "/Python", label: "Python" },
  { path: "/React", label: "React" },
];

const pages = [
  {
    path: "/Python",
    title: "Python",
    vid: () => <YoutubeEmbed embedId="x7X9w_GIm1s" />,
    logo: <FaPython />,
    links: links,
    key: 1,
    textarray: ["Fast", "Scalable", "Secure"],
    slogan: "Python Development Services that are ",
    backgroundImageTitle: "python",
    TextTitle: "python section paragraph",
    textType: "Tools_text",
  },
  {
    path: "/JavaScript",
    title: "Javascript",
    vid: () => <YoutubeEmbed embedId="DHjqpvDnNGE" />,
    logo: <IoLogoJavascript />,
    links: links,
    key: 2,
    textarray: ["Robust", "Efficient", "Optimized"],
    slogan: "JavaScript Apps that are",
    backgroundImageTitle: "javaScript",
    TextTitle: "javascript section paragraph",
    textType: "Tools_text",
  },
  {
    path: "/React",
    title: "React",
    vid: () => <YoutubeEmbed embedId="Tn6-PIqc4UM" />,
    logo: <FaReact />,
    links: links,
    key: 3,
    textarray: ["Reliable", "Reusable", "Responsive"],
    slogan: "React Development Services that are",
    backgroundImageTitle: "react",
    TextTitle: "react section paragraph",
    textType: "Tools_text",
  },
  {
    path: "/Flutter",
    title: "Flutter",
    vid: () => <YoutubeEmbed embedId="lHhRhPV--G0" />,
    logo: <SiFlutter />,
    links: links,
    key: 4,
    textarray: ["Cross-platform", "High-performance", "Responsive"],
    slogan: "Flutter Apps that are ",
    backgroundImageTitle: "Flutter",
    TextTitle: "flutter section paragraph",
    textType: "Tools_text",
  },
  {
    path: "/Node",
    title: "Node.js",
    vid: () => <YoutubeEmbed embedId="uVwtVBpw7RQ" />,
    logo: <FaNode size={40} />,
    links: links,
    key: 5,
    textarray: ["Cutting-Edge", "Seamless", "Dependable"],
    slogan: "Node.js Services that are ",
    backgroundImageTitle: "node",
    TextTitle: "node section paragraph",
    textType: "Tools_text",
  },
  {
    path: "/Github",
    title: "Github",
    vid: () => <YoutubeEmbed embedId="pBy1zgt0XPc" />,
    logo: <FaGithub />,
    links: links,
    key: 6,
    textarray: ["Efficient", "Collaborative", "Seamless"],
    slogan: "Github Version Control that is ",
    backgroundImageTitle: "github",
    TextTitle: "github section paragraph",
    textType: "Tools_text",
  },
  {
    path: "/Docker",
    title: "Docker",
    vid: () => <YoutubeEmbed embedId="Gjnup-PuquQ" />,
    logo: <SiDocker />,
    links: links,
    key: 7,
    textarray: ["Deployment", "Shipping", "Scalability"],
    slogan: "Docker Containers Built for",
    backgroundImageTitle: "docker",
    TextTitle: "docker section paragraph",
    textType: "Tools_text",
  },
  {
    path: "/Kubernetes",
    title: "Kubernetes",
    vid: () => <YoutubeEmbed embedId="PziYflu8cB8" />,
    logo: <SiKubernetes />,
    links: links,
    key: 8,
    textarray: ["Fast", "Scalable", "Secure"],
    slogan: "Kubernetes Clusters that are ",
    backgroundImageTitle: "kubernetes",
    TextTitle: "kubernetes section paragraph",
    textType: "Tools_text",
  },
  {
    path: "/Jenkins",
    title: "Jenkins",
    vid: () => <YoutubeEmbed embedId="hlpNgRcZyN0" />,
    logo: <SiJenkins />,
    links: links,
    key: 9,
    textarray: ["Productivity", "Efficiency", "Automation"],
    slogan: "Jenkins Pipelines for Better ",
    backgroundImageTitle: "jenkins",
    TextTitle: "jenkins section paragraph",
    textType: "Tools_text",
  },
  {
    path: "/Tools_Overview",
    title: "Overview",
    vid: null,
    logo: <IoEyeSharp color="white" size={50} />,
    links: links,
    key: 10,
    textarray: [
      "JavaScript",
      "React",
      "Node.js",
      "Python",
      "Flutter",
      "Docker",
      "Kubernetes",
      "Jenkins",
      "GitHub",
    ],
    slogan: "SWLancer Tools Include : ",
    backgroundImageTitle: "tools overview",
    TextTitle: "tools overview",
    textType: "Tools_text",
  },
];

export default pages;
