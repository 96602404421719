import React from "react";
import Navbar from "./Navbar";
import BasicPage from "./BasicPage";

const ServicesPage = ({
  key,
  header,
  listItems,
  text,
  icon,
  textarray,
  links,
  backgroundImageTitle,
  type,
  textType,
  TextTitle,
}) => (
  <>
    <Navbar isgrey={false} fixed={true} />
    <BasicPage
      header={header}
      listItems={listItems}
      text={text}
      icon={icon}
      textarray={textarray}
      links={links}
      key={key}
      backgroundImageTitle={backgroundImageTitle}
      type={type}
      textType={textType}
      TextTitle={TextTitle}
    />
  </>
);

export default ServicesPage;
