import React, { useState, useEffect } from "react";
import Logo from "../Assets/SWLANCER(4).png";
import TransparentLogo from "../Assets/SWLANCER(1).png";
import {
  FaLinkedin,
  FaGithub,
  FaReddit,
  FaBars,
  FaTimes,
} from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Navbar.css";
import ExpandingSearchBar from "../Tools/SearchBar";
import useDeviceType from "../Hooks/useDeviceType";
const Navbar = (props) => {
  const [isSticky, setIsSticky] = useState(props.fixed ? false : true);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const deviceType = useDeviceType();

  useEffect(() => {
    const handleScroll = () => {
      if (!props.fixed) {
        if (window.scrollY > 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const toggleDrawer = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    console.log(isOpen);
  };

  const handleClickOutside = (e) => {
    if (isOpen && !e.target.closest(".burger-menu")) {
      setIsOpen(false);
    }
  };

  let navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <>
      <header className={props.isgrey ? "header-nav grey" : "header-nav"}>
        <nav className={`nav-main ${!props.fixed && isSticky ? "sticky" : ""}`}>
          <div className="nav-logo" onClick={navigateToHome}>
            <img
              className={props.isgrey ? "Altlogo" : "logo"}
              alt="swlancer logo"
              src={props.isgrey ? Logo : TransparentLogo}
            ></img>
          </div>
          <Link
            to={"/AboutUs"}
            className={`${isOpen ? "main-links hidden" : "main-links"}     ${
              props.isgrey ? "text-dark" : ""
            }  ${location.pathname === "/AboutUs" ? "active-link" : ""}`}
          >
            STORY
          </Link>
          <Link
            to={`${
              deviceType === "mobile" ? "/Solutions" : "/Solutions_Overview"
            }`}
            className={`${isOpen ? "main-links hidden" : "main-links "}   ${
              props.isgrey ? "text-dark" : ""
            } ${
              location.pathname === "/Solutions" ||
              location.pathname === "/web_development" ||
              location.pathname === "/mobile_apps" ||
              location.pathname === "/chatbots" ||
              location.pathname === "/web_apps" ||
              location.pathname === "/web_services" ||
              location.pathname === "/Solutions_Overview" ||
              location.pathname === "/healthflow"
                ? "active-link"
                : ""
            }`}
          >
            SOLUTIONS
          </Link>
          <Link
            to={`${
              deviceType === "mobile" ? "/Services" : "/Services_Overview"
            }`}
            className={`${isOpen ? "main-links hidden" : "main-links "}  ${
              props.isgrey ? "text-dark" : ""
            } ${
              location.pathname === "/Services" ||
              location.pathname === "/containers" ||
              location.pathname === "/Managed_services" ||
              location.pathname === "/hosting" ||
              location.pathname === "/consulting" ||
              location.pathname === "/Data_Modeling" ||
              location.pathname === "/Services_Overview" ||
              location.pathname === "/healthflow"
                ? "active-link"
                : ""
            }`}
          >
            SERVICES
          </Link>
          <Link
            to={"https://healthflowonline.com"}
            className={`${isOpen ? "main-links hidden" : "main-links"}     ${
              props.isgrey ? "text-dark" : ""
            }  ${location.pathname === "/healthflow" ? "active-link" : ""}`}
          >
            HEALTHFLOW
          </Link>
          <Link
            to={`${deviceType === "mobile" ? "/tools" : "/Tools_Overview"}`}
            className={`${isOpen ? "main-links hidden" : "main-links "}  ${
              props.isgrey ? "text-dark" : ""
            } ${
              location.pathname === "/tools" ||
              location.pathname === "/React" ||
              location.pathname === "/Flutter" ||
              location.pathname === "/Github" ||
              location.pathname === "/Jenkins" ||
              location.pathname === "/Javascript" ||
              location.pathname === "/Docker" ||
              location.pathname === "/Kubernetes" ||
              location.pathname === "/Node" ||
              location.pathname === "/Python" ||
              location.pathname === "/Tools_Overview" ||
              location.pathname === "/healthflow"
                ? "active-link"
                : ""
            }`}
          >
            TOOLS
          </Link>
          <div className="burger search-burger">
            <div
              className={`${
                isOpen ? "search-barDiv hidden" : "search-barDiv"
              }  ${props.isgrey ? "text-dark" : ""} `}
            >
              <ExpandingSearchBar
                insideDrawer={false}
                withBorder={props.isgrey}
              />
            </div>
            <FaBars
              size={30}
              onClick={toggleDrawer}
              className={` navbar-burger-icon ${
                props.isgrey ? "text-dark" : ""
              }`}
            />
          </div>
          <div className={`burger-menu ${isOpen ? "open" : ""}`}>
            <div className="close-icon" onClick={toggleDrawer}>
              <FaTimes size={30} />
            </div>
            <div className="drawer-content">
              <div className="drawer-links">
                <div className="mobile-searchbar">
                  <ExpandingSearchBar insideDrawer={true} />
                </div>
                <Link to={"/AboutUs"}>
                  <div className="drawer-link-highlight"></div>
                  Story
                </Link>
                <Link to={"/Solutions"}>
                  <div className="drawer-link-highlight"></div>
                  Solutions
                </Link>
                <Link to={"/Services"}>
                  <div className="drawer-link-highlight"></div>
                  Services
                </Link>
                <Link to={"https://healthflowonline.com"}>
                  <div className="drawer-link-highlight"></div>
                  HealthFlow
                </Link>
                <Link to={"/tools"}>
                  <div className="drawer-link-highlight"></div>
                  Tools
                </Link>
                <Link to={"/Careers"}>
                  <div className="drawer-link-highlight"></div>
                  Careers
                </Link>
                <Link to={"/ContactUs"}>
                  <div className="drawer-link-highlight"></div>
                  Contact Us
                </Link>
                <div className="socials-container">
                  <div className="socials">
                    <a href={"https://www.reddit.com/user/SWLancer/"}>
                      <FaReddit size={25} />
                    </a>
                    <a href={"https://www.github.com/swlancer"}>
                      <FaGithub size={25} />
                    </a>
                    <a href={"https://www.linkedin.com/company/swlancer"}>
                      <FaLinkedin size={25} />
                    </a>
                    <a href={"https://wa.me/96176407089"}>
                      <RiWhatsappFill size={25} />
                    </a>
                  </div>
                </div>
              </div>
              <footer className="drawer-footer">
                Copyright © 2023 | SWLANCER
              </footer>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
