import React from "react";
import Footer from "../Components/Footer";
import "./Solutions.css";
import Navbar from "../Components/Navbar";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../Assets/icons8-medical-96.png";

const Portfolio = () => {
  let navigate = useNavigate();
  const NavigateTo = (path) => {
    navigate(`/${path}`);
  };

  const cards = [
    {
      path: "HealthFlow",
      imageUrl: logo,
      altText: "HealthFlow icon",
      cardText: "HealthFlow",
    },
  ];

  return (
    <div className="solutions-BG">
      <Navbar isgrey={true} />
      <Container style={{ padding: "7rem" }}>
        <Row>
          <Col>
            <h1 className="text-center pt-5">Portfolio</h1>
          </Col>
        </Row>
        <Row className="justify-content-md-center my-4">
          {cards.map((card) => (
            <Col md="auto" key={card.path}>
              <Card
                className="mb-3 w-100 custom-card border-0"
                onClick={() => NavigateTo(card.path)}
              >
                <div
                  className="d-flex flex-column align-items-start gap-4"
                  style={{ padding: "1.5rem" }}
                >
                  <img src={card.imageUrl} alt={card.altText} />
                  <p className="cards-txt">
                    Health<span color="#1565C0">Flow</span>
                  </p>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default Portfolio;
