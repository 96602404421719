import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button, Form } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { IoMdCheckmark } from "react-icons/io";
import { BsTelephone } from "react-icons/bs";
import "./BasicPage.css";
import { Link, useLocation } from "react-router-dom";
import styles from "./LanguagePages.module.css";
import axios from "axios";
import useTextArrayAnimation from "../Hooks/useTextArrayAnimation";
import SanityImage from "./SanityImage";
import SanityText from "./SanityText";

const CustomComponent = ({
  key,
  header,
  text,
  listItems,
  icon,
  textarray,
  links,
  backgroundImageTitle,
  type,
  textType,
  TextTitle,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [messageColor, setMessageColor] = useState("");
  const [overview, setOverview] = useState(false);

  const [errorFields, setErrorFields] = useState({
    name: false,
    email: false,
    phone: false,
    company: false,
    message: false,
  });
  const location = useLocation();
  const delaySpeed = 800;
  const animatedText = useTextArrayAnimation(textarray, delaySpeed, key);

  const openQuote = async () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  const submitData = async () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    if (show) {
      if (!name || !email || !phone || !company || !message) {
        setError("Please fill in all fields!");
        setMessageColor("red");
        setErrorFields({
          name: !name,
          email: !email,
          phone: !phone,
          company: !company,
          message: !message,
        });
        return;
      }
      const subject = "Demo Request";
      const data = {
        name,
        email,
        phone,
        company,
        message,
        subject,
      };

      try {
        await axios.post(`${apiUrl}/send-email`, data);
        setError("Email sent successfully");
        setMessageColor("green");
      } catch (error) {
        setError("Error sending email");
        setMessageColor("red");
      }
    }
  };

  const handleInputChange = (e, field) => {
    if (errorFields[field]) {
      setErrorFields({ ...errorFields, [field]: false });
    }

    switch (field) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
      case "company":
        setCompany(e.target.value);
        break;
      case "message":
        setMessage(e.target.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (
      location.pathname === "/Solutions_Overview" ||
      location.pathname === "/Services_Overview"
    ) {
      setOverview(true);
    } else {
      setOverview(false);
    }
  }, [location.pathname]);

  const [show, setShow] = useState(false);

  return (
    <>
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "55vh", backgroundColor: "#4a4a4a" }}
      >
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Request A Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="justify-content-md-center">
              <Col md={12}>
                <Form className="mb-3">
                  {error && (
                    <p
                      style={{
                        color: messageColor,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {error}
                    </p>
                  )}
                  <Form.Label>
                    We would love to discuss and provide you with a quote.
                    Please fill in the below details.
                  </Form.Label>
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        controlId="name"
                        className={`mb-3 ${
                          errorFields.name ? "error-field" : ""
                        }`}
                      >
                        <Form.Control
                          type="text"
                          placeholder="name *"
                          onChange={(e) => handleInputChange(e, "name")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        controlId="email"
                        className={`mb-3 ${
                          errorFields.email ? "error-field" : ""
                        }`}
                      >
                        <Form.Control
                          type="email"
                          placeholder="Email *"
                          onChange={(e) => handleInputChange(e, "email")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        controlId="phone"
                        className={`mb-3 ${
                          errorFields.phone ? "error-field" : ""
                        }`}
                      >
                        <Form.Control
                          type="tel"
                          placeholder="phone number *"
                          onChange={(e) => handleInputChange(e, "phone")}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        controlId="company"
                        className={`mb-3 ${
                          errorFields.company ? "error-field" : ""
                        }`}
                      >
                        <Form.Control
                          type="text"
                          placeholder="company name *"
                          onChange={(e) => handleInputChange(e, "company")}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group
                    controlId="message"
                    className={`mb-3 ${
                      errorFields.message ? "error-field" : ""
                    }`}
                  >
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message *"
                      onChange={(e) => handleInputChange(e, "message")}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <div className="modal-contactus-footer">
                Or contact us at{" "}
                <a
                  href="mailto:info@swlancer.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@swlancer.com
                </a>{" "}
                or via{" "}
                <a href="tel:+96176407089" className="ModalPhoneNumber">
                  +961 76 407089
                </a>
              </div>
            </Row>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleClose}
                className="ModalBtnCancel"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => submitData()}
                className="ModalBtnSend"
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        <div className="pages-background-image-slider">
          <SanityImage
            imageTitle={backgroundImageTitle}
            className="pages-bg-img"
            type={type}
          />
        </div>
        <Row className="pages-content">
          <Col
            style={{ paddingTop: "7.5rem" }}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <Row className="pages-header-row">
              <h1 className="pages-header">
                <div className="icon-container">{icon}</div>
                {header}
              </h1>
            </Row>
            <Row
              className="pages-slogan-row align-items-center justify-content-center"
              style={{ marginRight: "3rem" }}
            >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <p className="pages-slogan text-break mb-0">
                    <span style={{ display: "inline-block" }}>{text}</span>
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        position: "absolute",
                        top: "0",
                        marginLeft: "0.5rem",
                      }}
                      className="animated-text"
                    >
                      {animatedText}
                    </span>
                  </p>
                </div>
              </div>
            </Row>
            <Row className="pages-button-row align-items-center justify-content-center">
              <div className="pages-button-container">
                <button className="pages-btn" onClick={openQuote}>
                  REQUEST QUOTE
                </button>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <section
        className={styles.brandingFirstSection}
        style={{ paddingTop: "5rem" }}
      >
        <Container fluid className="h-100">
          <div className={styles.row}>
            <aside className={styles.colLg4}>
              <div
                id={styles.PHBody_tdLeftSection}
                className={styles.AltleftMenuContainer}
              >
                {links.map(({ path, label }) => (
                  <Link
                    key={path}
                    to={path}
                    className={`${
                      location.pathname === path
                        ? `${styles.ActiveLanguagePage}`
                        : ""
                    }`}
                  >
                    {label}
                  </Link>
                ))}
              </div>
            </aside>

            <div className={`${styles.colLg7} ${styles.py4}`}>
              <SanityText
                className="text-start BasicPage-text"
                textTitle={TextTitle}
                textType={textType}
              />
              {!overview && (
                <>
                  <h5 className="pages-bullets-header">
                    <strong>KEY FEATURES:</strong>
                  </h5>
                  <div className="pages-bullet">
                    <ul>
                      {listItems.map((item) => (
                        <li key={item}>
                          <IoMdCheckmark className="pages-bullets-icon " />
                          <p className="pages-bullets-text">{item}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </Container>
      </section>
      <section className="pages-footer">
        <div className="pages-footer-bg-gradient">
          <div>
            <h4 className="footer-title">GET IN TOUCH TODAY!</h4>
          </div>
          <div className="pages-footer-div">
            <div className="pages-footer-content">
              <div className="content-first">
                <BsTelephone size={20} className="footer-content-icon" />
                <div className="footer-headers">
                  Phone Number
                  <div className="footer-sub-headers">
                    <a href="tel:+96176407089">+961 76 407089</a>
                  </div>
                </div>
              </div>
              <div className="content-second">
                <AiOutlineMail size={20} className="footer-content-icon" />
                <div className="footer-headers">
                  Email Address
                  <div className="footer-sub-headers">
                    {" "}
                    <a
                      href="mailto:info@swlancer.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      info@swlancer.com
                    </a>
                  </div>
                </div>
              </div>
              <div className=""></div>
            </div>
          </div>
        </div>
      </section>
      <footer className="pages-footer-footer">
        Copyright © 2023 | SOFTWARE LANCER - SWLANCER
      </footer>
    </>
  );
};

export default CustomComponent;
