import React, { useState, useEffect } from "react";
import sanityClient from "../sanityClient";
import BlockContent from "@sanity/block-content-to-react";

const SanityText = ({ textTitle, textType, className }) => {
  const [textContent, setTextContent] = useState(null);

  useEffect(() => {
    const getTextData = async () => {
      const query = `*[_type == '${textType}']`;
      const textData = await sanityClient.fetch(query);
      return textData || [];
    };

    getTextData().then((textData) => {
      const targetText = textData.find((text) => text.title === textTitle);
      setTextContent(targetText.content);
    });
  }, [textTitle, textType]);

  return (
    <div className={className}>
      {textContent && <BlockContent blocks={textContent} />}
    </div>
  );
};

export default SanityText;
