import React from "react";
import { FaLinkedin, FaGithub, FaReddit } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { RiWhatsappFill } from "react-icons/ri";
import "./footer.css";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import CustomModal from "./customModal";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [CookieModal, setcookieModal] = React.useState(false);
  const [TermsModal, setTermsModal] = React.useState(false);
  const handleClose = () => {
    setcookieModal(false);
    setTermsModal(false);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const navigate = useNavigate();

  const openAbout = () => {
    navigate("/aboutUs");
  };

  const openPage = (page) => {
    navigate(`/${page}`);
  };

  const handleConfirm = () => {
    setcookieModal(false);
    setTermsModal(false);
  };
  const modalTerms = (
    <>
      <p style={{ fontSize: "1rem" }}>
        A. Our Warranties and Disclaimers<br></br>
        <br></br> We provide our Services using a commercially reasonable level
        of care and promise to do our best to make sure you enjoy the Services.
        But there are certain things that we don’t promise about our Services.
        OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OF SERVICE, NEITHER
        SWLANCER.COM, INC. NOR ITS AGENTS OR SERVICE PROVIDERS (THE “SERVICES
        ENTITIES”) MAKE ANY SPECIFIC PROMISES ABOUT THE SITES. FOR EXAMPLE, WE
        DON’T MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SITES, THE
        SPECIFIC FUNCTION OF THE SITES, OR THEIR RELIABILITY, AVAILABILITY, OR
        ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE SITES “AS IS”. SOME
        JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY
        OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
        NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL
        WARRANTIES.<br></br>
        <br></br> B. Liability for our Services  <br></br>
        <br></br>EXCEPT WHERE PROHIBITED, THE SERVICES ENTITIES SHALL NOT BE
        LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
        EXEMPLARY DAMAGES ARISING FROM YOUR USE OF THE SITES OR ANY THIRD
        PARTY’S USE OF THE SITES. THESE EXCLUSIONS INCLUDE, WITHOUT LIMITATION,
        DAMAGES FOR LOST PROFITS, LOST DATA, COMPUTER FAILURE, OR THE VIOLATION
        OF YOUR RIGHTS BY ANY THIRD PARTY, EVEN IF THE SERVICES ENTITIES HAVE
        BEEN ADVISED OF THE POSSIBILITY THEREOF AND REGARDLESS OF THE LEGAL OR
        EQUITABLE THEORY UPON WHICH THE CLAIM IS BASED. <br></br>
        <br></br> C. Chatbot Data Capture<br></br>
        <br></br>
        To improve the performance of our website chatbot service, we need to
        capture all interactions for chatbot model tuning and enhancement. In
        addition, we collect users' IP addresses to determine their country of
        origin and limit chatbot usage to prevent abuse. Our chatbot is built
        using a fine-tuned ChatGPT model, but it may occasionally provide
        inaccurate information or offensive comments depending on the user
        inquiry. By using our chatbot, you agree to these terms and conditions.
        <br></br>
        <br></br>D. Additional Details<br></br>
        <br></br>
        We may modify these Terms at any time so be sure to check back
        regularly. By continuing to use the Site after these Terms have changed,
        you indicate your agreement to the revised Terms. If you do not agree to
        the changes, you should stop using or logging in to the Sites. The Sites
        may contain links to third-party websites. That doesn’t mean that we
        control or endorse those websites, or any goods or services sold on
        those websites. Similarly, the Sites may contain ads from third-parties.
        We do not control or endorse any products being advertised. If you do
        not comply with these Terms, and we don’t take action right away, this
        doesn’t mean we’re OK with what you did, or we are giving up any rights
        that we may have (such as taking action in the future). These Terms are
        governed by and construed in accordance with the laws of Lebanon,
        without regard to its conflict of laws rules. You expressly agree that
        the exclusive jurisdiction for any claim or dispute under these Terms
        and or your use of the Services resides in the courts located in Beirut,
        Lebanon, and you further expressly agree to submit to the personal
        jurisdiction of such courts for the purpose of litigating any such claim
        or action. If it turns out that a particular provision in these Terms is
        not enforceable, that will not affect any other provision. These terms
        were last updated on April 1, 2023.<br></br>
      </p>
    </>
  );

  const modalCookies = (
    <>
      <p style={{ fontSize: "1rem" }}>
        Cookie Policy Last updated: 1 April,2023<br></br>
        <br></br>Welcome to Swlancer, a software development startup that
        specializes in providing software as a service (SaaS) solutions and
        creating custom software. This Cookie Policy explains how our website,
        swlancer.com, uses cookies and similar technologies to improve your
        browsing experience, analyze website traffic, and enable specific
        features. By using our website, you consent to the use of cookies in
        accordance with this Cookie Policy.<br></br>
        <br></br> What are cookies?<br></br>
        <br></br>Cookies are small text files that are stored on your computer
        or mobile device when you visit a website. They are used to remember
        your preferences, enable certain website features, and collect
        information about your interactions with the website.<br></br>
        <br></br> How Swlancer uses cookies?<br></br> <br></br>Swlancer uses
        cookies for the following purposes:<br></br>
        <br></br> Essential cookies: These cookies are necessary for the proper
        functioning of our website and cannot be turned off. They are usually
        only set in response to actions made by you, such as logging in, filling
        out forms, or setting your privacy preferences.
        <br></br> <br></br>Performance and analytics cookies:<br></br>
        <br></br>
        These cookies help us understand how visitors interact with our website
        by collecting information about the pages visited, the time spent on the
        website, and any errors that may occur. We use Google Analytics to
        gather this information, which is aggregated and anonymized to protect
        your privacy.<br></br>
        <br></br> Functionality cookies: These cookies enable the website to
        provide enhanced functionality and personalization. For example, they
        may be used to remember your preferred language or region.
        <br></br>
        <br></br> Third-party cookies: Our website integrates third-party
        services, such as reCAPTCHA and the OpenAI API, to improve security,
        provide additional features, and offer personalized content. These
        services may set their own cookies, which are governed by their
        respective privacy policies.<br></br> <br></br>Managing your cookie
        preferences
        <br></br>
        <br></br> You can manage your cookie preferences by adjusting the
        settings in your web browser. Most browsers allow you to block or delete
        cookies, as well as receive notifications when new cookies are set.
        Please note that if you choose to block or delete cookies, some features
        of our website may not function properly, and your user experience may
        be negatively affected.<br></br>
        <br></br>
        Updates to this Cookie Policy<br></br> <br></br>We may update this
        Cookie Policy from time to time to reflect changes in our practices,
        technologies, legal requirements, or for other reasons. We will notify
        you of any material changes by posting the updated policy on our website
        with the "Last updated" date at the beginning of the policy. We
        encourage you to periodically review this policy to stay informed about
        our use of cookies and related technologies.<br></br>
        <br></br> Contact us If you have any questions or concerns about this
        Cookie Policy, please contact us at: <br></br>
        <br></br>Swlancer<br></br>
        <br></br> Beirut,Lebanon <br></br>
        <br></br>Email: swlancer.lb@gmail.com <br></br>
        <br></br>By using our website, you consent to the use of cookies and
        similar technologies in accordance with this Cookie Policy.
      </p>
    </>
  );
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="col1">
          <h4 className="footer-header">Solutions</h4>
          <ul className="l1">
            <li onClick={() => openPage("web_development")}>Web Development</li>
            <li onClick={() => openPage("web_apps")}>Web Apps</li>
            <li onClick={() => openPage("mobile_apps")}>Mobile Development</li>
            <li onClick={() => openPage("web_services")}>Web Services</li>
            <li onClick={() => openPage("chatbots")}>Chatbots</li>
          </ul>
        </div>
        <div className="col2">
          <h4 className="footer-header">Services</h4>
          <ul className="l2">
            <li onClick={() => openPage("hosting")}>Hosting</li>
            <li onClick={() => openPage("consulting")}>Consultancy</li>
            <li onClick={() => openPage("containers")}>
              Containerized Workloads
            </li>
            <li onClick={() => openPage("data_modeling")}>Data Modeling</li>
            <li onClick={() => openPage("managed_services")}>
              Managed Services
            </li>
          </ul>
        </div>
        <div className="col3">
          <h4>Tools</h4>
          <ul className="l3">
            <li onClick={() => openPage("react")}>React</li>
            <li onClick={() => openPage("javascript")}>Javascript</li>
            <li onClick={() => openPage("node")}>Node</li>
            <li onClick={() => openPage("python")}>Python</li>
            <li onClick={() => openPage("flutter")}>Flutter</li>
            <li onClick={() => openPage("github")}>Github</li>
            <li onClick={() => openPage("docker")}>Docker</li>
            <li onClick={() => openPage("kubernetes")}>Kubernetes</li>
            <li onClick={() => openPage("jenkins")}>Jenkins</li>
          </ul>
        </div>
        <div className="col4">
          <h4>More</h4>
          <ul className="l4">
            <li onClick={openAbout}>About</li>
            <li onClick={() => openPage("contactUs")}>Contact Us</li>
            <li onClick={() => openPage("careers")}>Careers</li>
          </ul>
        </div>
        <div className="col5">
          <ul className="contact-list">
            <li>
              <span className="footer-contact-span">
                <BsTelephoneFill size={17} />
                <a href="tel:+96176407089">+961 76 407 089</a>
              </span>
            </li>
            <li>
              <span className="footer-contact-span">
                <MdEmail size={20} className="footer-contact-span-icon" />
                <a href="mailto:info@swlancer.com">info@swlancer.com</a>
              </span>
            </li>
            <li>
              <span className="footer-contact-span">
                <IoLocationSharp size={22} />
                Beirut, Lebanon
              </span>
            </li>
          </ul>

          <ul className="social-list">
            <li>
              <a
                href="https://www.reddit.com/user/SWLancer/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaReddit
                  size={25}
                  color="black"
                  className="footer-socialMediaLinks"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.github.com/swlancer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaGithub
                  size={25}
                  color="black"
                  className="footer-socialMediaLinks"
                />
              </a>
            </li>
            <li>
              <a
                href={"https://www.linkedin.com/company/swlancer"}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin
                  size={25}
                  color="black"
                  className="footer-socialMediaLinks"
                />
              </a>
            </li>
            <li>
              <a
                href="https://wa.me/96176407089"
                target="_blank"
                rel="noopener noreferrer"
              >
                <RiWhatsappFill
                  size={27}
                  color="black"
                  className="footer-socialMediaLinks"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-list">
          <ul>
            <CustomModal
              show={CookieModal}
              handleClose={handleClose}
              title="Cookie Policy"
              body={modalCookies}
              cancelButtonText="Close"
              confirmButtonText="Confirm"
              handleConfirm={handleConfirm}
            />
            <CustomModal
              show={TermsModal}
              handleClose={handleClose}
              title="Terms of Service"
              body={modalTerms}
              cancelButtonText="Close"
              confirmButtonText="Confirm"
              handleConfirm={handleConfirm}
            />
            <li onClick={reloadPage}>SWLancer.com</li>
            <li onClick={() => setTermsModal(true)}>Terms of Service</li>
            <li onClick={() => setcookieModal(true)}>Cookie Policy</li>
          </ul>
        </div>
        <div className="copyright">
          &copy; SWLancer 2023. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
