import React from "react";
import Footer from "../Components/Footer";
import "./Solutions.css";
import Navbar from "../Components/Navbar";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";
import { BsRobot } from "react-icons/bs";
import { IoIosApps } from "react-icons/io";
import { MdOutlineWeb } from "react-icons/md";

const Solutions = () => {
  let navigate = useNavigate();
  const NavigateTo = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div className="solutions-BG">
      <Navbar isgrey={true} />
      <Container style={{ padding: "7rem" }}>
        <Row>
          <Col>
            <h1 className="text-center pt-5">Solutions</h1>
          </Col>
        </Row>
        <Row className="justify-content-md-center my-4">
          <Col md="auto">
            <Card
              className="mb-3 w-100 custom-card border-0"
              onClick={() => NavigateTo("web_development")}
            >
              <div
                className="d-flex flex-column align-items-start gap-4"
                style={{ padding: "1.5rem" }}
              >
                <MdOutlineWeb size={80} className="basic-page-icon" />
                <p className="cards-txt">WEBSITE DEVELOPMENT</p>
              </div>
            </Card>
          </Col>
          <Col md="auto">
            <Card className="mb-3 w-100 custom-card  border-0">
              <div
                className="d-flex flex-column align-items-start gap-4"
                style={{ padding: "1.5rem" }}
                onClick={() => NavigateTo("web_apps")}
              >
                <IoIosApps size={80} className="basic-page-icon" />
                <p className="cards-txt">WEB APPS</p>
              </div>
            </Card>
          </Col>
          <Col md="auto">
            <Card className="mb-3 w-100 custom-card  border-0">
              <div
                className="d-flex flex-column align-items-start gap-4"
                style={{ padding: "1.5rem" }}
                onClick={() => NavigateTo("web_services")}
              >
                <MdOutlineMiscellaneousServices
                  size={80}
                  className="basic-page-icon"
                />
                <p className="cards-txt">WEB SERVICES</p>
              </div>
            </Card>
          </Col>
          <Col md="auto">
            <Card className="mb-3 w-100 custom-card  border-0">
              <div
                className="d-flex flex-column align-items-start gap-4"
                style={{ padding: "1.5rem" }}
                onClick={() => NavigateTo("mobile_apps")}
              >
                <CiMobile3 size={80} className="basic-page-icon" />
                <p className="cards-txt">MOBILE APPS</p>
              </div>
            </Card>
          </Col>
          <Col md="auto">
            <Card className="mb-3 w-100 custom-card  border-0">
              <div
                className="d-flex flex-column align-items-start gap-4"
                style={{ padding: "1.5rem" }}
                onClick={() => NavigateTo("chatbots")}
              >
                <BsRobot size={80} className="basic-page-icon" />
                <p className="cards-txt">CHATBOTS</p>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default Solutions;
