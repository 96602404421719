import React from "react";
import Footer from "../Components/Footer";
import "./Solutions.css";
import Navbar from "../Components/Navbar";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoLogoJavascript } from "react-icons/io";
import { SiDocker, SiFlutter, SiJenkins, SiKubernetes } from "react-icons/si";
import { FaGithub, FaNode, FaPython, FaReact } from "react-icons/fa";

const Tools = () => {
  let navigate = useNavigate();
  const NavigateTo = (path) => {
    navigate(`/${path}`);
  };

  const cards = [
    {
      path: "React",
      imageUrl: <FaReact size={35} className="basic-page-icon" />,
      altText: "react icon",
      cardText: "React",
    },
    {
      path: "Python",
      imageUrl: <FaPython size={35} className="basic-page-icon" />,
      altText: "Python icon",
      cardText: "Python",
    },
    {
      path: "Javascript",
      imageUrl: <IoLogoJavascript size={35} className="basic-page-icon" />,
      altText: "javascript icon",
      cardText: "JavaScript",
    },
    {
      path: "Flutter",
      imageUrl: <SiFlutter size={35} className="basic-page-icon" />,
      altText: "Flutter icon",
      cardText: "Flutter",
    },
    {
      path: "Github",
      imageUrl: <FaGithub size={35} className="basic-page-icon" />,
      altText: "Github icon",
      cardText: "GitHub",
    },
    {
      path: "Docker",
      imageUrl: <SiDocker size={35} className="basic-page-icon" />,
      altText: "Docker icon",
      cardText: "Docker",
    },
    {
      path: "Kubernetes",
      imageUrl: <SiKubernetes size={35} className="basic-page-icon" />,
      altText: "Kubernetes icon",
      cardText: "Kubernetes",
    },
    {
      path: "Jenkins",
      imageUrl: <SiJenkins size={35} className="basic-page-icon" />,
      altText: "Jenkins icon",
      cardText: "Jenkins",
    },
    {
      path: "Node",
      imageUrl: <FaNode size={35} className="basic-page-icon" />,
      altText: "Node icon",
      cardText: "Node Js",
    },
  ];

  return (
    <div className="solutions-BG">
      <Navbar isgrey={true} />
      <Container style={{ padding: "7rem" }}>
        <Row>
          <Col className="pt-3 pt-5">
            <h1 className="text-center">Our Tools</h1>
          </Col>
        </Row>
        <Row className="justify-content-md-center my-4">
          {cards.map((card) => (
            <Col md="auto" key={card.path}>
              <Card
                className="mb-3 w-100 custom-card border-0"
                onClick={() => NavigateTo(card.path)}
              >
                <div
                  className="d-flex flex-column align-items-start gap-4"
                  style={{ padding: "1.5rem" }}
                >
                  <div>{card.imageUrl}</div>
                  <p className="cards-txt">{card.cardText}</p>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer />
    </div>
  );
};
export default Tools;
