import React from "react";
import styles from "./AboutUs.module.css";
import Navbar from "../Components/Navbar";
import CarouselComponent from "../Components/Carousel.js";
import SlickSlider from "../Components/slick-slider.js";
import Footer from "../Components/Footer.js";
import { Link } from "react-router-dom";
import { Card, Container, Col } from "react-bootstrap";
import webDev from "../Assets/webDev.jpg";
import server from "../Assets/server.jpg";
import mobileapp from "../Assets/mobileApp.jpg";
import webservices from "../Assets/WebServices.png";
import webapps from "../Assets/webapps.jpg";
import { IoLogoJavascript } from "react-icons/io";
import { SiDocker, SiFlutter, SiJenkins, SiKubernetes } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import { FaGithub, FaNode, FaPython, FaReact } from "react-icons/fa";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";
import { BsRobot } from "react-icons/bs";
import { MdOutlineWeb } from "react-icons/md";
import Slider from "react-slick";
const AboutUs = () => {
  const cardsData = [
    {
      heading: "WEB DEVELOPMENT",
      imageUrl: webDev,
      path: "web_development",
      logo: <MdOutlineWeb size={45} />,
    },
    {
      heading: "WEB SERVICES",
      imageUrl: webservices,
      path: "web_services",
      logo: <MdOutlineMiscellaneousServices size={45} />,
    },
    {
      heading: "MOBILE APPS",
      imageUrl: mobileapp,
      path: "mobile_apps",
      logo: <CiMobile3 size={45} />,
    },
    {
      heading: "CHATBOTS",
      imageUrl: server,
      path: "chatbots",
      logo: <BsRobot size={45} />,
    },
    {
      heading: "WEB APPS",
      imageUrl: webapps,
      path: "web_apps",
      logo: <MdOutlineWeb size={45} />,
    },
  ];

  let navigate = useNavigate();
  const navigateTo = (url) => {
    navigate(`/${url}`);
  };

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 6,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const Altsettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <main className={styles.aboutUsMain}>
      <Navbar isgrey={true} fixed={false} />
      <section id={styles.aboutUs} className={styles.aboutUsSection}>
        <div className={styles.AboutUsHeader}>
          <div>
            <h1>About Us</h1>
            <p>
              SWLancer is a group of dedicated experts united by a single goal:
              to deliver high-quality solutions that excel in design, features,
              and security. We specialize in DevOps, providing end-to-end
              software solutions development processes that leverage Continuous
              Integration and Continuous Deployment (CI/CD) practices. We adopt
              Agile project management methodologies to ensure the success of
              our solution development processes utilizing Kanban boards to
              streamline workflows and maximize efficiency.
            </p>
          </div>
        </div>
      </section>
      <section id={styles.aboutUsSlider} className={styles.ourMissionSection}>
        <CarouselComponent />
      </section>
      <section className={styles.sliderSection}>
        <SlickSlider />
      </section>
      <section className={styles.AboutUsSolutions}>
        <h2 className={styles.AboutUsSolutionsHeader}>Our Solutions</h2>
        <Container
          style={{ paddingLeft: "6rem" }}
          className={styles.CardsContainer}
        >
          <Slider {...Altsettings} className={styles.AltSlider}>
            {cardsData.map((card, index) => (
              <Col key={index} xs={12} md={6} lg={3}>
                <Card
                  className={styles.AboutUsSolutionsCards}
                  onClick={() => navigateTo(card.path)}
                >
                  <img
                    src={card.imageUrl}
                    alt={card.heading}
                    className={styles.cardImage}
                  />
                  <Card.Body
                    className={`${styles.enlargedCardBody} enlargedCardBody d-flex align-items-center justify-content-end flex-column gap-3`}
                  >
                    <div>{card.logo}</div>
                    <Card.Title>{card.heading}</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Slider>
        </Container>
      </section>
      <section className={styles.languagesSection}>
        <section className={`${styles.languages} ${styles.borderTop}`}>
          <h6
            className={` ${styles.caps} ${styles.textDark} ${styles.languagesSectionHeader}`}
          >
            Our Tools
          </h6>
          <Slider className={styles.langList} {...settings}>
            <div className={styles.linkWrapper}>
              <Link to="/Node" className={styles.node}>
                <FaNode size={45} />
                Node.js
              </Link>
            </div>
            <div className={styles.linkWrapper}>
              <Link to="/Github" className={styles.git}>
                <FaGithub size={45} />
                Github
              </Link>
            </div>
            <div className={styles.linkWrapper}>
              <Link to="/JavaScript" className={styles.js}>
                <IoLogoJavascript size={45} />
                Javascript
              </Link>
            </div>
            <div className={styles.linkWrapper}>
              <Link to="/React" className={styles.react}>
                <FaReact size={45} />
                React
              </Link>
            </div>
            <div className={styles.linkWrapper}>
              <Link to="/Python" className={styles.python}>
                <FaPython size={45} />
                Python
              </Link>
            </div>
            <div className={styles.linkWrapper}>
              <Link to="/Flutter" className={styles.flutter}>
                <SiFlutter size={45} />
                Flutter
              </Link>
            </div>
            <div className={styles.linkWrapper}>
              <Link to="/Docker" className={styles.docker}>
                <SiDocker size={45} />
                Docker
              </Link>
            </div>
            <div className={styles.linkWrapper}>
              <Link to="/Kubernetes" className={styles.kube}>
                <SiKubernetes size={45} />
                Kubernetes
              </Link>
            </div>
            <div className={styles.linkWrapper}>
              <Link to="/Jenkins" className={styles.jenkins}>
                <SiJenkins size={45} />
                Jenkins
              </Link>
            </div>
          </Slider>
        </section>
      </section>
      <footer>
        <Footer />
      </footer>
    </main>
  );
};

export default AboutUs;
