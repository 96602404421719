import React from "react";
import { Modal } from "react-bootstrap";
const CustomModal = (props) => {
  const { show, handleClose, title, body } = props;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
export default CustomModal;
