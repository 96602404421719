import React, { useCallback } from "react";
import "./homepage.css";
import "./homepageMobile.css";
import { useEffect, useRef } from "react";
import ChatbotWeb from "../Components/Chatbot";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../Components/Navbar";
import webdev from "../Assets/webDev.jpg";
import server from "../Assets/server.jpg";
import mobileapp from "../Assets/mobileApp.jpg";
import webservices from "../Assets/WebServices.png";
import ReactFullpage from "@fullpage/react-fullpage";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import devops from "../Assets/devOps.png";
import MERN from "../Assets/mern.jpg";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { CiMobile3 } from "react-icons/ci";
import { BsRobot } from "react-icons/bs";
import { CgWebsite } from "react-icons/cg";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoIosApps } from "react-icons/io";

function Homepage() {
  const location = useLocation();
  const fullpageRef = useRef();

  useEffect(() => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const sectionClass = params.get("section");
      const element = document.querySelector(`.${sectionClass}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location.search]);

  let navigate = useNavigate();

  const navigateToAboutUs = () => {
    navigate("/AboutUs");
  };

  const navigateTo = useCallback(
    (page) => {
      navigate(`/${page}`);
    },
    [navigate]
  );

  const handleButtonClick = (section) => {
    if (fullpageRef.current) {
      fullpageRef.current.fullpageApi.moveTo(section);
    }
  };

  return (
    <div className="Container">
      <Navbar className="fixed-navbar" />
      <ReactFullpage
        licenseKey={""}
        scrollingSpeed={1000}
        ref={fullpageRef}
        navigation={true}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <div className="section">
                <div className="gradient-overlay"></div>
                <section className="hero">
                  <div className="hero-div ">
                    <h1 className={"heading-hero light-mode"}>
                      <span
                        style={{
                          fontFamily: "Comfortaa",
                          letterSpacing: "0.3rem",
                          fontSize: "3rem",
                          fontWeight: "bolder",
                          color: "transparent",
                          opacity: "1%",
                        }}
                      >
                        {"</>"}
                      </span>{" "}
                      SOFTWARE LANCER
                    </h1>
                    <p className="slogan light-mode">
                      Transform your business with our custom software
                      solutions! Boost productivity, streamline processes, and
                      reach new heights. Partner with us for exceptional service
                      and innovation.
                    </p>
                    <button
                      className="glitch-button"
                      onClick={navigateToAboutUs}
                    >
                      READ MORE
                    </button>
                    <div className="background-image-slider">
                      <img
                        src={devops}
                        alt="graphical illustration of a devops cycle "
                        className="bg-img"
                        loading="lazy"
                      ></img>
                    </div>
                  </div>
                  <span>
                    <Link
                      className="scroll-indicator"
                      onClick={() => handleButtonClick(2)}
                    >
                      <span className="landing-mouse">
                        <IoIosArrowDown
                          size={30}
                          color="white"
                          className="arrow-down-home"
                        />
                      </span>
                    </Link>
                  </span>
                </section>
              </div>
              <div className="section">
                <div className="gradient-overlay-2"></div>
                <section className="sec">
                  <div className="d-flex justify-content-center align-items-center vh-100">
                    <Container fluid className="container-responsive">
                      <div className="header-icon-home pb-3">
                        <CgWebsite
                          size={60}
                          color="var(--brand-green)"
                          className="header-icon-landing"
                        />
                      </div>
                      <Row>
                        <Col xs={12} sm={10} md={7} lg={7} xl={7}>
                          <h1 className="secondary-hero light-mode">
                            WEB DEVELOPMENT
                          </h1>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col xs={12} sm={10} md={5} lg={7} xl={7}>
                          <p className="slogan text-break light-mode">
                            SWLancer offers a range of web development solutions
                            to cater to different business requirements. We
                            specialize in eCommerce development solutions and
                            can build online stores using popular online
                            platforms.
                          </p>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col xs={12} sm={10} md={7} lg={7} xl={7}>
                          <div style={{ display: "flex" }}>
                            <button
                              className="glitch-button"
                              onClick={() => navigateTo("web_development")}
                            >
                              READ MORE
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div className="background-image-slider">
                    <img
                      src={webdev}
                      alt="web development code on a screen"
                      className="bg-img"
                      loading="lazy"
                    ></img>
                  </div>
                  <span>
                    <Link
                      className="scroll-indicator"
                      onClick={() => handleButtonClick(3)}
                    >
                      <span className="landing-mouse">
                        <IoIosArrowDown
                          size={30}
                          color="white"
                          className="arrow-down-home"
                        />
                      </span>
                    </Link>
                  </span>
                </section>
              </div>
              <div className="section">
                <div className="gradient-overlay-2"></div>
                <section className="sec">
                  <div className="d-flex justify-content-center align-items-center vh-100">
                    <Container fluid className="container-responsive">
                      <div className="header-icon-home pb-3">
                        <IoIosApps
                          size={60}
                          color="var(--brand-green)"
                          className="header-icon-landing"
                        />
                      </div>
                      <Row>
                        <Col xs={12} sm={10} md={7} lg={7} xl={7}>
                          <h1 className="secondary-hero light-mode">
                            WEB APPS
                          </h1>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col xs={12} sm={10} md={5} lg={7} xl={7}>
                          <p className="slogan text-break light-mode">
                            SWLancer offers comprehensive Full Stack web
                            development solutions.  Our team of skilled
                            developers is proficient in front-end and back-end
                            solution development, with expertise in React, C#,
                            Python, MongoDB, Express, and many more to deliver
                            the perfect custom solution  needed for your
                            business.
                          </p>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col xs={12} sm={10} md={7} lg={7} xl={7}>
                          <div style={{ display: "flex" }}>
                            <button
                              className="glitch-button"
                              onClick={() => navigateTo("web_apps")}
                            >
                              READ MORE
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div className="background-image-slider">
                    <img
                      src={MERN}
                      alt="MERN development stack logos"
                      className="bg-img"
                      loading="lazy"
                    ></img>
                  </div>
                  <span>
                    <Link
                      className="scroll-indicator"
                      onClick={() => handleButtonClick(4)}
                    >
                      <span className="landing-mouse">
                        <IoIosArrowDown
                          size={30}
                          color="white"
                          className="arrow-down-home"
                        />
                      </span>
                    </Link>
                  </span>
                </section>
              </div>
              <div className="section">
                <div className="gradient-overlay-3"></div>
                <section className="sec">
                  <div className="d-flex justify-content-center align-items-center vh-100">
                    <Container fluid className="container-responsive">
                      <div className="header-icon-home pb-3">
                        <MdOutlineMiscellaneousServices
                          size={60}
                          color="var(--brand-green)"
                          className="header-icon-landing"
                        />
                      </div>
                      <Row>
                        <Col xs={12} sm={10} md={7} lg={7} xl={7}>
                          <h1 className="secondary-hero light-mode">
                            WEB SERVICES
                          </h1>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col xs={12} sm={10} md={5} lg={7} xl={7}>
                          <p className="slogan text-break light-mode">
                            SWLancer web services encompass micro web services
                            solution design and deployment for higher
                            availability, scaling, and resiliency. We
                            additionally offer Web APIs development for legacy
                            business platforms. Our team of experienced
                            developers works closely with clients to create
                            custom web solutions that meet their unique business
                            needs.
                          </p>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col xs={12} sm={10} md={7} lg={7} xl={7}>
                          <div style={{ display: "flex" }}>
                            <button
                              className="glitch-button"
                              onClick={() => navigateTo("web_services")}
                            >
                              READ MORE{" "}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div className="background-image-slider">
                    <img
                      src={webservices}
                      alt="graphic illustration of web services"
                      className="bg-img"
                      loading="lazy"
                    ></img>
                  </div>
                  <span>
                    <Link
                      className="scroll-indicator"
                      onClick={() => handleButtonClick(5)}
                    >
                      <span className="landing-mouse">
                        <IoIosArrowDown
                          size={30}
                          color="white"
                          className="arrow-down-home"
                        />
                      </span>
                    </Link>
                  </span>
                </section>
              </div>
              <div className="section">
                <div className="gradient-overlay-3"></div>
                <section className="sec">
                  <div className="d-flex justify-content-center align-items-center vh-100">
                    <Container fluid className="container-responsive">
                      <div className="header-icon-home pb-3">
                        <CiMobile3
                          size={60}
                          color="var(--brand-green)"
                          className="header-icon-landing"
                        />
                      </div>
                      <Row>
                        <Col xs={12} sm={10} md={7} lg={7} xl={7}>
                          <h1 className="secondary-hero light-mode">
                            MOBILE APPS
                          </h1>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col xs={12} sm={10} md={5} lg={7} xl={7}>
                          <p className="slogan text-break light-mode">
                            SWLancer specializes in mobile app development using
                            Flutter and React Native for both iOS and Android
                            mobile operating systems. Our team of experienced
                            developers works closely with clients to create
                            custom mobile app solutions that meet their unique
                            business needs.
                          </p>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col xs={12} sm={10} md={7} lg={7} xl={7}>
                          <div style={{ display: "flex" }}>
                            <button
                              className="glitch-button"
                              onClick={() => navigateTo("mobile_apps")}
                            >
                              READ MORE
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div className="background-image-slider">
                    <img
                      src={mobileapp}
                      alt="closeup of an iphone"
                      className="bg-img"
                      loading="lazy"
                    ></img>
                  </div>
                  <span>
                    <Link
                      className="scroll-indicator"
                      onClick={() => handleButtonClick(6)}
                    >
                      <span className="landing-mouse">
                        <IoIosArrowDown
                          size={30}
                          color="white"
                          className="arrow-down-home"
                        />
                      </span>
                    </Link>
                  </span>
                </section>
              </div>
              <div className="section">
                <div className="gradient-overlay-2"></div>
                <section className="sec">
                  <div className="d-flex justify-content-center align-items-center vh-100">
                    <Container fluid className="container-responsive">
                      <div className="header-icon-home pb-3">
                        <BsRobot
                          size={60}
                          color="var(--brand-green)"
                          className="header-icon-landing"
                        />
                      </div>
                      <Row>
                        <Col xs={12} sm={10} md={7} lg={7} xl={7}>
                          <h1 className="secondary-hero light-mode">
                            CHATBOTS
                          </h1>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col xs={12} sm={10} md={5} lg={7} xl={7}>
                          <p className="slogan text-break light-mode">
                            SWLancer provides development and deployment of
                            chatbot technologies using TensorFlow and ChatGPT.
                            We offer custom chatbot solutions that cater to
                            different business needs, ensuring that customers
                            can easily interact with your business in a seamless
                            and efficient manner.
                          </p>
                        </Col>
                      </Row>
                      <Row className="align-items-start">
                        <Col xs={12} sm={10} md={7} lg={7} xl={7}>
                          <div style={{ display: "flex" }}>
                            <button
                              className="glitch-button"
                              onClick={() => navigateTo("chatbots")}
                            >
                              READ MORE
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  <div className="background-image-slider">
                    <img
                      src={server}
                      alt="Motherboard with a gigabyte logo"
                      className="bg-img"
                      loading="lazy"
                    ></img>
                  </div>
                  <span>
                    <Link
                      className="scroll-indicator"
                      onClick={() => handleButtonClick(1)}
                    >
                      <span className="landing-mouse">
                        <IoIosArrowUp
                          size={30}
                          color="white"
                          className="arrow-down-home"
                        />
                      </span>
                    </Link>
                  </span>
                </section>
              </div>
            </ReactFullpage.Wrapper>
          );
        }}
      />
      <ChatbotWeb />
    </div>
  );
}

export default Homepage;
