import React from "react";
import { Carousel } from "react-bootstrap";
import "./CarouselStyle.css";
import pc from "../Assets/laptop.png";
import data from "../Assets/data.png";
import tool from "../Assets/tools.png";
import ui from "../Assets/ui.png";

function CarouselComponent() {
  return (
    <div className="Carousel-container">
      <Carousel
        indicators={true}
        interval={5500}
        controls={false}
        className="custom-carousel"
      >
        <Carousel.Item>
          <div className="slider-div">
            <div className="slider-image">
              <img src={pc} alt="laptop doodle"></img>
            </div>
            <div className="slider-text">
              <h1>Web Development Excellence</h1>
              <p className="carousel-body-text">
                At SWLancer, our web development functions encompass a broad
                range of services, designed to provide comprehensive,
                cutting-edge solutions tailored to your specific needs. Trust in
                our dedicated team of experienced developers to deliver
                exceptional results for your online presence.
              </p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="slider-div">
            <div className="slider-image">
              <img src={data} alt="data containers doodle"></img>
            </div>
            <div className="slider-text">
              <h1>Accelerate Online Success</h1>
              <p className="carousel-body-text">
                Unleash the full potential of your online business with
                SWLancer. Our expert team collaborates with you to create
                innovative and engaging digital experiences that captivate your
                target audience, driving growth and fostering long-term success
                in today's competitive online marketplace.
              </p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="slider-div">
            <div className="slider-image">
              <img src={tool} alt="programming process doodle"></img>
            </div>
            <div className="slider-text">
              <h1>Enhance & Protect Your Apps</h1>
              <p className="carousel-body-text">
                Enhance your apps and maximize security with SWLancer's robust
                and reliable development services. Our experts focus on creating
                user-friendly, feature-rich applications while maintaining the
                highest standards of security to protect your valuable data and
                ensure a seamless user experience.
              </p>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="slider-div">
            <div className="slider-image">
              <img src={ui} alt="user profile doodle"></img>
            </div>
            <div className="slider-text">
              <h1>Superior Hosting Performance</h1>
              <p className="carousel-body-text">
                Experience powerful hosting solutions with SWLancer, designed to
                provide optimal performance and reliability. Our
                state-of-the-art hosting infrastructure guarantees fast load
                times, minimal downtime, and scalable resources, ensuring that
                your online presence remains consistent and efficient in today's
                fast-paced digital world.
              </p>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default CarouselComponent;
