import React, { useEffect, useCallback } from "react";
import Styles from "./SuggestionsPanel.module.css";

const SuggestionsPanel = ({ suggestions, onSuggestionClick }) => {
  const handleSuggestionMouseDown = useCallback(
    (suggestion, event) => {
      console.log("Event:", event);
      const isTouchEvent = event.type === "touchstart";
      const touchProperties = isTouchEvent ? event.targetTouches[0] : null;
      console.log("Touch properties:", touchProperties);
      event.preventDefault();
      onSuggestionClick(suggestion.value, suggestion.origin);
    },
    [onSuggestionClick]
  );

  const handleTouchStart = useCallback(
    (event) => {
      const listItemText = event.target.textContent;
      const suggestion = suggestions.find(
        (suggestion) => suggestion.value === listItemText
      );

      if (!suggestion) {
        return;
      }

      if (event.preventDefault) {
        event.preventDefault();
      }
      console.log("Touch start:", suggestion);
      handleSuggestionMouseDown(suggestion, event);
    },
    [suggestions, handleSuggestionMouseDown]
  );

  const addNonPassiveEventListener = (element, eventName, eventHandler) => {
    const options = { passive: false };
    element.addEventListener(eventName, eventHandler, options);
  };

  useEffect(() => {
    const listItems = document.querySelectorAll(".suggestionsPanel li");
    listItems.forEach((listItem) => {
      addNonPassiveEventListener(listItem, "touchstart", handleTouchStart);
    });

    return () => {
      listItems.forEach((listItem) => {
        listItem.removeEventListener("touchstart", handleTouchStart);
      });
    };
  }, [suggestions, handleTouchStart]);

  return (
    <div className={`${Styles.suggestionsPanel}`}>
      <ul>
        {suggestions.map((suggestion, index) => (
          <li
            key={index}
            id={`suggestion-${suggestion.value}`}
            onClick={(event) => handleSuggestionMouseDown(suggestion, event)}
          >
            {suggestion.value}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SuggestionsPanel;
