import React from "react";
import Navbar from "../Components/Navbar";
import { Container } from "react-bootstrap";
import Footer from "../Components/Footer";

const Careers = () => {
  return (
    <div>
      <Navbar isgrey={true} />
      <Container
        fluid
        style={{ paddingTop: "10rem" }}
        className="careers_container"
      >
        <h1>Careers @ SWLancer</h1>
        <h2 className="careers_header">
          There are no current openings! Please check back later for new opportunities.
        </h2>
      </Container>
      <Footer />
    </div>
  );
};

export default Careers;
