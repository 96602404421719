import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "./Navbar";
import styles from "./LanguagePages.module.css";
import { Link, useLocation } from "react-router-dom";
import Footer from "./Footer";
import useTextArrayAnimation from "../Hooks/useTextArrayAnimation";
import SanityImage from "./SanityImage";
import SanityText from "./SanityText";

const LanguagesPage = ({
  title,
  vid: Video,
  logo,
  key,
  textarray,
  links,
  slogan,
  backgroundImageTitle,
  textType,
  TextTitle,
}) => {
  const location = useLocation();
  const delaySpeed = 1000;
  const animatedText = useTextArrayAnimation(textarray, delaySpeed, key);
  const [overview, setOverview] = useState(false);

  useEffect(() => {
    if (location.pathname === "/Tools_Overview") {
      setOverview(true);
    } else {
      setOverview(false);
    }
  }, [location.pathname]);

  return (
    <>
      <Navbar isgrey={false} />
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "55vh", backgroundColor: "#4a4a4a" }}
      >
        <div className="pages-background-image-slider">
          <SanityImage
            imageTitle={backgroundImageTitle}
            className="pages-bg-img"
            type={"tools_images"}
          />
        </div>
        <Row className="pages-content">
          <Col
            style={{ paddingTop: "7.5rem" }}
            className="d-flex flex-column justify-items-center align-items-center"
          >
            <Row className="pages-header-row">
              <h1 className="pages-header">
                <div className="icon-container">{logo}</div>
                {title}
              </h1>
            </Row>
            <Row
              className="pages-slogan-row align-items-center justify-content-center"
              style={{ marginRight: "3rem" }}
            >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <p className="pages-slogan text-break mb-0">
                    <span style={{ display: "inline-block" }}>{slogan}</span>
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        position: "absolute",
                        top: "0",
                        marginLeft: "0.5rem",
                      }}
                      className="animated-text"
                    >
                      {animatedText}
                    </span>
                  </p>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
      <section
        className={styles.brandingFirstSection}
        style={{ paddingTop: "5rem" }}
      >
        <Container fluid className="h-100">
          <div className={styles.row}>
            <aside className={styles.colLg4}>
              <div
                id={styles.PHBody_tdLeftSection}
                className={styles.AltleftMenuContainer}
              >
                {links.map(({ path, label }) => (
                  <Link
                    key={path}
                    to={path}
                    className={`${
                      location.pathname === path
                        ? `${styles.ActiveLanguagePage}`
                        : ""
                    }`}
                  >
                    {label}
                  </Link>
                ))}
              </div>
            </aside>
            <div className={`${styles.colLg7} ${styles.py4}`}>
              <SanityText
                className={"text-start BasicPage-text"}
                textTitle={TextTitle}
                textType={textType}
              />
              {!overview && (
                <div className={styles.videoContainer}>
                  {Video && <Video />}
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
      <footer>
        <Footer />
      </footer>
    </>
  );
};

export default LanguagesPage;
