import { useState, useEffect } from "react";

const useTextArrayAnimation = (textarray, delaySpeed, key) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentText, setCurrentText] = useState(textarray[currentIndex]);
  const [isTyping, setIsTyping] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [cursor] = useState("|");

  useEffect(() => {
    const timer = setTimeout(
      () => {
        if (isTyping) {
          setCurrentText(
            (prevText) =>
              prevText + textarray[currentIndex].charAt(prevText.length)
          );
          if (currentText.length === textarray[currentIndex].length) {
            setIsTyping(false);
            setIsPaused(true); // Set pause state to true
          }
        } else {
          if (isPaused) {
            setTimeout(() => {
              setIsPaused(false); // Reset pause state after delay
            }, delaySpeed);
          } else {
            setCurrentText((prevText) => prevText.slice(0, -1));
            if (currentText.length === 0) {
              setCurrentIndex(
                (prevIndex) => (prevIndex + 1) % textarray.length
              );
              setIsTyping(true);
            }
          }
        }
      },
      isTyping ? delaySpeed / textarray[currentIndex].length : delaySpeed / 20
    );

    return () => {
      clearTimeout(timer);
    };
  }, [
    currentText,
    isTyping,
    currentIndex,
    textarray,
    delaySpeed,
    key,
    isPaused,
  ]);

  return currentText + cursor;
};

export default useTextArrayAnimation;
